<template>
 <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
  <!--begin::Card-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <!--begin::Svg Icon | path: icons/duotone/General/Search.svg-->
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                  opacity="0.3"
                />
                <path
                  d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                  fill="#000000"
                  fill-rule="nonzero"
                />
              </g>
            </svg>
          </span>
          <!--end::Svg Icon-->
          <input
            type="text"
            class="form-control form-control-solid w-250px ps-14"
            placeholder="ค้นหา"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <div
          class="d-flex justify-content-end"
          data-kt-user-table-toolbar="base"
        >
          <!--begin::Add user-->
          <router-link
            v-if="can('user-create', 'all')"
            to="/settings/users/create"
          >
            <button type="button" class="btn btn-primary">
              <!--begin::Svg Icon | path: icons/duotone/Navigation/Plus.svg-->
              <span class="svg-icon svg-icon-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <rect
                    fill="#000000"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                  <rect
                    fill="#000000"
                    opacity="0.5"
                    transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000)"
                    x="4"
                    y="11"
                    width="16"
                    height="2"
                    rx="1"
                  />
                </svg>
              </span>
              <!--end::Svg Icon-->เพิ่มผู้ใช้งาน
            </button>
          </router-link>
          <!--end::Add user-->
        </div>
        <!--end::Toolbar-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body pt-0">
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table align-middle table-row-dashed fs-6 gy-5"
          id="kt_table_users"
          style="border-collapse: unset"
        >
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->

            <tr
              class="
                text-start text-gray-400
                fw-bolder
                fs-7
                text-uppercase
                gs-0
              "
            >
              <th class="min-w-125px">ชื่อผู้ใช้งาน</th>
              <th class="min-w-125px">ชื่อ</th>
              <th class="min-w-125px">ระดับผู้ใช้งาน</th>

              <th class="text-end min-w-100px">จัดการ</th>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="text-gray-600 fw-bold">
            <!--begin::Table row-->
            <template v-for="user in users" :key="user.id">
              <tr>
                <!--begin::User=-->
                <td class="d-flex align-items-center">
                  <!--begin::User details-->
                  <div class="d-flex flex-column">
                    <a class="text-gray-800 text-hover-primary mb-1">{{
                      user.username
                    }}</a>
                    <span>{{ user.email }}</span>
                  </div>
                  <!--begin::User details-->
                </td>
                <!--end::User=-->
                <td class="align-items-center">{{ user.name }}</td>
                <td class="align-items-center">
                  <div v-if="user.roles.length > 0">
                    <label
                      class="badge badge-success m-1"
                      v-for="v in user.roles"
                      :key="v.id"
                      >{{ v }}</label
                    >
                  </div>
                </td>

                <!--begin::Action=-->
                <td class="text-end">
                  <a
                    href="#"
                    class="btn btn-light btn-active-light-primary btn-sm"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                    >จัดการ
                    <!--begin::Svg Icon | path: icons/duotone/Navigation/Angle-down.svg-->
                    <span class="svg-icon svg-icon-5 m-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(12.000003, 11.999999) rotate(-180.000000) translate(-12.000003, -11.999999)"
                          />
                        </g>
                      </svg>
                    </span>
                    <!--end::Svg Icon--></a
                  >

                  <!--begin::Menu-->
                  <div
                    class="
                      menu
                      menu-sub
                      menu-sub-dropdown
                      menu-column
                      menu-rounded
                      menu-gray-600
                      menu-state-bg-light-primary
                      fw-bold
                      fs-7
                      w-125px
                      py-4
                    "
                    data-kt-menu="true"
                  >
                    <!--begin::Menu item-->
                    <div class="menu-item px-3" v-if="can('user-edit', 'all')">
                      <router-link
                        :to="{
                          name: 'settings.users.edit',
                          params: { id: user.id },
                        }"
                      >
                        <a class="menu-link px-3">แก้ไข</a>
                      </router-link>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div
                      class="menu-item px-3"
                      v-if="can('user-delete', 'all')"
                    >
                      <a @click="delateUser(user.id)" class="menu-link px-3"
                        >ลบ</a
                      >
                    </div>
                    <!--end::Menu item-->
                  </div>
                  <!--end::Menu-->
                </td>
                <!--end::Action=-->
              </tr>
              <!--end::Table row-->
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <Pagination v-bind:meta="meta" @pagination="updateHandler"></Pagination>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Card-->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import useUsers from "@/core/services/api/users";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import useSweetalert from "@/core/helpers/sweetalert2";
import { checkPage } from "@/core/helpers/checkpage";
import { useAbility } from "@casl/vue";
import {
  MenuComponent,
  DrawerComponent,
  ToggleComponent,
} from "@/assets/js/components";
import { onMounted, onUpdated ,ref} from "vue";

export default {
  components: {
    Pagination,
  },
  setup() {
    const users = ref([]);
    const meta = ref([]);
     let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const { getUsers, destroyUser } = useUsers();
    const { Sconfirm, SToast } = useSweetalert();
    const { can } = useAbility();
    onMounted(() => {
      checkPage("user-list");
      setCurrentPageBreadcrumbs("ผู้ใช้งาน", []);
       getUsers().then((response) => {
        users.value = response.data.data;
        meta.value = response.data.meta;
        isLoading.value = false;
      });
      DrawerComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
      MenuComponent.reinitialization();
    });
    function delateUser(id) {
      Sconfirm("ยืนยันการลบ", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;
          await destroyUser(id)
          getUsers().then((response) => {
        users.value = response.data.data;
        meta.value = response.data.meta;
        isLoading.value = false;
      });
          SToast("success", "ลบ ผู้ใช้งาน สำเร็จ");
        }
      });
    }
     async function updateHandler(e) {
      isLoading.value = true;
      getUsers(e).then((response) => {
        users.value = response.data.data;
        meta.value = response.data.meta;
        isLoading.value = false;
      });
    }

    return {
      can,
      users,
      meta,
      delateUser,
      updateHandler,
       isLoading, color
    };
  },
};
</script>
